<template>
  <div class="QualityReportUnpublishForm">
    <form-panel ref="formPanel" v-bind="submitConfig" :form="form" :submitBefore="submitBefore" @update="update">
      <template #headerSlot>
        <v-button text="导出PDF" @click="exportPDF" v-loading.fullscreen.lock="loading"></v-button>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <template #saveAfterSlot>
        <v-button text="完成" @click="complete" v-show="isShowComplent"></v-button>
      </template>
      <col2-detail>
        <div id="exportPage">
          <h3 class="text_center">{{ reportTitle }}</h3>
          <col2-block v-show="reportDetail.displayModel == 1">
            <col2-item label="项目名称" :text="reportDetail.communityName"></col2-item>
            <col2-item label="本项目分值总计">
              {{ reportDetail.totalMark }}，其中不涉及项分值总计：{{ reportDetail.noAccountScore }}
            </col2-item>
            <col2-item label="报告生成时间" :text="reportDetail.reportCreateTime"></col2-item>
            <col2-item label="本项目扣分总计" :text="reportDetail.deductPoints"></col2-item>
            <col2-item label="检查人" :text="reportDetail.executeUserName"></col2-item>
            <col2-item label="本项目检查得分" :text="reportDetail.checkScore"></col2-item>
            <col2-item label="本项目最终得分" :text="reportDetail.finalScore"></col2-item>
            <col2-item label="退回原因" :text="reportDetail.backspaceSpecifics"></col2-item>
          </col2-block>
          <col2-block v-show="reportDetail.displayModel == 2">
            <col2-item label="项目名称" :text="reportDetail.communityName"></col2-item>
            <col2-item label="本项目总检查项">
              {{ reportDetail.totalScore }} 项，其中不涉及项总计：{{ reportDetail.noAccountScore }} 项
            </col2-item>
            <col2-item label="报告生成时间" :text="reportDetail.reportCreateTime"></col2-item>
            <col2-item label="本项目合格项">
              {{ reportDetail.checkScore }} 项
            </col2-item>
            <col2-item label="检查人" :text="reportDetail.executeUserName"></col2-item>
            <col2-item label="本项目不合格项">
              {{ reportDetail.deductPoints }} 项
            </col2-item>
            <col2-item label="本项目最终合格项">
              {{ reportDetail.finalScore }} 项
            </col2-item>
            <col2-item label="退回原因">
              {{ reportDetail.backspaceSpecifics }}
            </col2-item>
          </col2-block>
          <col2-block v-for="(item, index) in Object.keys(tableData)" :key="index">
            <h4 class="text_center">{{ item }}</h4>
            <table-panel :tableData="tableData[item]" :hasOperateColumn="false" :headers="headers" :autoHeight="true"
              :showOverflowTooltip="false">
            </table-panel>
          </col2-block>
        </div>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { editURL, queryURL, uploadURL, getListURL } from './api'
import { planTypeMap } from './map'
import { vUploader, vTextarea } from 'components/control'
import { Col2Detail, Col2Block, Col2Item, CheckboxPlus, TablePanel } from 'components/bussiness'
import { createImgVNode } from 'common/vdom'
import {
  getRectifyHeader,
  getScoreHeader,
} from './helper.js'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'


export default {
  name: 'QualityReportUnpublishForm',
  components: {
    vUploader,
    vTextarea,
    Col2Detail,
    Col2Block,
    Col2Item,
    CheckboxPlus,
    TablePanel
  },
  computed: {
    reportTitle () {
      const { planName, planType } = this.reportDetail;
      if (!planName && !planType) {
        return '品质报告'
      }
      return `${planName}${planTypeMap[planType] || ''}表`
    },
    headers () {
      const { displayModel = 1 } = this.reportDetail
      let isDetail = !this.isEdit || this.isExport
      let isRectify = this.isRectify;
      let scoreHeader = getScoreHeader.call(this, !isDetail, displayModel, isRectify)
      let rectifyHeader = getRectifyHeader.call(this, !isDetail, isRectify)

      return [
        ...this.headers1,
        ...scoreHeader,
        ...this.headers2,
        ...rectifyHeader,
      ]
    },
    isEdit () {
      // 整改完成前可编辑
      let { rectificationProgress } = this.reportDetail
      let { submitUrl = '' } = this.submitConfig
      return [1, 2].includes(+rectificationProgress) && submitUrl
    },
    isShowComplent () {
      // 整改完成前存在该按钮
      let { rectificationProgress } = this.reportDetail
      let { submitUrl = '' } = this.submitConfig
      return [1, 2].includes(+rectificationProgress) && submitUrl
    }
  },
  data () {
    return {
      width: 200,
      uploadURL,
      isRectify: undefined,
      isExport: false,
      loading: false,
      form: {
      },
      tableData: [

      ],
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: ''
      },
      reportDetail: {
        displayModel: 1
      },
      planTypeMap,
      searchUrl: getListURL,
      searchParams: {},
      headers1: [
        {
          prop: 'itemName',
          label: '检查项名称'
        },
        {
          prop: 'itemCriterion',
          width: 150,
          label: '检查标准'
        },
        {
          prop: 'itemDescription',
          width: 250,
          label: '检查方法及判定细则'
        },
        {
          prop: 'isNoAccount',
          label: '是否为不涉及项',
          formatter (row, prop) {
            // 1是 0否
            return row[prop] == 0 ? '否' : '是'
          }
        },
      ],
      headers2: [
        {
          prop: 'executeTime',
          label: '检查时间'
        },
        {
          prop: 'checkDetail',
          label: '检查详情'
        },
        {
          prop: 'scenePhotos',
          label: '现场照片',
          formatter: (row, prop) => {
            let nodeList = []
            row[prop] && row[prop].split(',').forEach(imgUrl => {
              nodeList.push(createImgVNode(this, row, prop, {
                imgUrl,
                style: {
                  marginRight: '2px',
                  marginBottom: '2px'
                }
              }))
            })
            return this.$createElement('div', {
              style: {
                'display': 'flex',
                'flex-wrap': 'wrap',
              }
            }, nodeList)
          }
        },
      ]
    }
  },
  mounted () {
    const { id, isDetail, isRectify } = this.$route.query
    this.isRectify = isRectify;
    if (id !== undefined) {
      if (isDetail !== undefined) {
        this.$setBreadcrumb('查看')
      } else {
        this.submitConfig.submitUrl = editURL + '?type=1'
        this.$setBreadcrumb('编辑')
      }
      this.$refs.formPanel.getData({ taskId: id, type: 2 })
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    exportPDF () {
      this.loading = true
      this.$nextTick(() => {
        this.isExport = true
        let __time__ = setTimeout(() => {
          clearTimeout(__time__)
          let dom = document.getElementById('exportPage')
          html2canvas(dom, {
            useCORS: true,
            async: true,
            scale: 2,
            dpi: 300,
            allowTaint: true
          }).then((canvas) => {
            const { width: canvasW, height: canvasH } = canvas
            console.log(canvas, "dfffffffffff")
            const base64 = canvas.toDataURL('image/png', 1.0)
            const pageW = 595.28
            const pageH = 841.89
            const pdfScale = pageW / canvasW
            const allH = canvasH * pdfScale
            const pdf = new jsPDF('p', 'px', [pageW, Math.max(allH, pageH)])

            pdf.addImage(base64, 'PNG', 0, 0, pageW, allH)

            // let position = 0
            // let leftH = allH
            // let img = document.createElement('img')
            // img.setAttribute('src', base64)
            // document.body.appendChild(img)
            //
            // if (leftH <= pageH) {
            //
            // }else{
            // 	while (leftH > 0) {
            // 		pdf.addImage(base64, 'PNG', 0, position, pageW, allH)
            // 		leftH -= pageH
            // 		position -= pageH
            // 		if (leftH > 0) {
            // 			pdf.addPage()
            // 		}
            // 	}
            // }

            pdf.save(`${this.reportTitle}.pdf`)
            this.isExport = false
            this.loading = false
          })
        })
      })


    },
    update (data) {
      Object.keys(data).forEach(key => {
        if ([undefined, null, ''].includes(data[key])) {
          return
        }
        this.$set(this.reportDetail, key, data[key])
      })
      const { taskDetailList = [], rectificationProgress } = data
      if (![1, 2].includes(rectificationProgress)) {
        this.submitConfig.submitUrl = ''
      }
      let tableList = {}
      // 根据分类生成对应列表
      taskDetailList.forEach((item) => {
        if (!tableList[item.categoryName1]) {
          tableList[item.categoryName1] = []
        }
        tableList[item.categoryName1].push(item)
      })
      this.tableData = tableList
    },
    submitBefore () {
      return this.getSaveParams()
    },
    getSaveParams () {
      let list = []
      Object.keys(this.tableData).forEach((key) => {
        list = list.concat(this.tableData[key])
      })
      return list
    },
    complete () {
      let result = this.$refs.formPanel.validate();
      if (!result) {
        return;
      }
      this.$axios.post(editURL, this.getSaveParams(), {
        params: {
          type: 2
        }
      }).then((res) => {
        const { status } = res
        if (status === 100) {
          this.$message.success('操作成功')
          this.goBack()
        }
      })
    },

  }
}
</script>
<style scoped lang="scss">
.text_center {
  text-align: center;
}

#exportPage {
  overflow: auto;
  padding: 0 20px;
}

::v-deep .el-form-item__content {
  margin: 0 !important
}

::v-deep .el-table {
  .cell {
    overflow: unset;
  }

  .vnode-wrapper {
    overflow: unset;
  }

}
</style>
